<template>
  <el-dialog :title="title" :visible.sync="visible" class="unitAdd" :close-on-click-modal="false" :close-on-press-escape="false" @close="close">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="submit" label-width="160px">
      <el-form-item prop="name" label="单位名称">
        <el-input v-model="dataForm.name" placeholder="请填写单位名称" size="small"></el-input>
      </el-form-item>
      <el-form-item prop="allowDecimalId" label="单位是否允许小数">
        <el-select v-model="dataForm.allowDecimalId" class="fullWidth" placeholder="请选择单位是否允许小数" @change="levelChanged" size="small">
          <el-option v-for="(item, idx) in unitAllowDecimalList" :key="idx" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'unitAdd',
  data () {
    return {
      visible: false,
      id: null,
      dataForm: {
        name: '',
        allowDecimalId: '',
        allowDecimalName: ''
      },
      dataRule: {
        name: [{ required: true, message: '请填写单位名称', trigger: 'blur' }],
        allowDecimalId: [{ required: true, message: '请选择单位是否允许小数', trigger: 'blur' }]
      },
      unitAllowDecimalList: []
    }
  },
  computed: {
    title () {
      return `${this.id ? '修改' : '新增'}单位`
    }
  },
  methods: {
    init (item) {
      this.getUnitAllowDecimalMap()
      if (item && item.id) {
        let id = this.id = item.id
        // 读取客户数据
        this.getInfo(id)
      } else {
        this.id = null
        let form = this.$options.data().dataForm
        this.$set(this, 'dataForm', form)
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 获取单位信息
    getInfo (id) {
      this.$http.get(`/unit/${id}`).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          let form = cloneDeep(data)
          this.$set(this, 'dataForm', form)
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 关闭窗口
    close () {
      this.visible = false
      this.$emit('reload')
    },
    // 提交数据
    submit: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return false
        let func = (f) => this.id ? this.$http.put('/unit', f) : this.$http.post('/unit', f)
        let form = cloneDeep(this.dataForm)
        func(form).then(({ data: res }) => {
          let { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
          } else {
            this.close()
          }
        })
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    // 获取单位是否允许小数Map
    getUnitAllowDecimalMap () {
      this.unitAllowDecimalList = []
      this.$http.get('/unit/unitAllowDecimal').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          this.unitAllowDecimalList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    levelChanged (val) {
      let row = this.unitAllowDecimalList.find((v) => v.value === val)
      console.log(row)
      this.dataForm.allowDecimalName = row.label
    }
  }
}
</script>
<style lang="scss">
.unitAdd {
  .fullWidth {
    width: 100%;
  }
}
</style>
